import { cn } from '@/lib/utils';

const DialogHeader = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
}) => <div className={cn('p-4', className)} {...props} />;

DialogHeader.displayName = 'DialogHeader';

export default DialogHeader;
