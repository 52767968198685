import { Dialog } from '@headlessui/react';
import * as React from 'react';

import { cn } from '@/lib/utils';

const DialogTitle = React.forwardRef<
    React.ElementRef<typeof Dialog.Title>,
    React.ComponentPropsWithoutRef<typeof Dialog.Title> & {
        className?: string;
    }
>(({ className, ...props }, ref) => (
    <Dialog.Title ref={ref} className={cn('pr-6', className)} {...props} />
));

DialogTitle.displayName = Dialog.Title.displayName;

export default DialogTitle;
