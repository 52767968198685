import { Dialog } from '@headlessui/react';
import * as React from 'react';

import { cn } from '@/lib/utils';

const DialogDescription = React.forwardRef<
    React.ElementRef<typeof Dialog.Description>,
    React.ComponentPropsWithoutRef<typeof Dialog.Description> & {
        className?: string;
    }
>(({ className, ...props }, ref) => (
    <Dialog.Description ref={ref} className={cn('', className)} {...props} />
));

DialogDescription.displayName = Dialog.Description.displayName;

export default DialogDescription;
