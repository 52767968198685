'use client';

import { Button } from '@components/ui/Button';
import { ArrowLeftIcon, ArrowRightIcon } from '@ngg/icons';
import useEmblaCarousel, {
    type UseEmblaCarouselType,
} from 'embla-carousel-react';
import type { ComponentProps, HTMLAttributes } from 'react';
import {
    createContext,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { cn } from '@/lib/utils';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
    opts?: CarouselOptions;
    plugins?: CarouselPlugin;
    orientation?: 'horizontal' | 'vertical';
    setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
    carouselRef: ReturnType<typeof useEmblaCarousel>[0];
    api: ReturnType<typeof useEmblaCarousel>[1];
    scrollPrev: () => void;
    scrollNext: () => void;
    canScrollPrev: boolean;
    canScrollNext: boolean;
    scrollTo: (index: number) => void;
    selectedIndex: number;
    scrollSnaps: number[];
} & CarouselProps;

const CarouselContext = createContext<CarouselContextProps | null>(null);

function useCarousel() {
    const context = useContext(CarouselContext);

    if (!context) {
        throw new Error('useCarousel must be used within a <Carousel />');
    }

    return context;
}

const Carousel = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
    (
        {
            orientation = 'horizontal',
            opts,
            setApi,
            plugins,
            className,
            children,
            ...props
        },
        ref,
    ) => {
        const [carouselRef, api] = useEmblaCarousel(
            {
                ...opts,
                axis: orientation === 'horizontal' ? 'x' : 'y',
            },
            plugins,
        );
        const [canScrollPrev, setCanScrollPrev] = useState(false);
        const [canScrollNext, setCanScrollNext] = useState(false);
        const [selectedIndex, setSelectedIndex] = useState(0);
        const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

        const onInit = useCallback((api: CarouselApi) => {
            if (!api) {
                return;
            }

            setScrollSnaps(api.scrollSnapList());
        }, []);

        const onSelect = useCallback((api: CarouselApi) => {
            if (!api) {
                return;
            }

            setSelectedIndex(api.selectedScrollSnap());
            setCanScrollPrev(api.canScrollPrev());
            setCanScrollNext(api.canScrollNext());
        }, []);

        const scrollPrev = useCallback(() => {
            api?.scrollPrev();
        }, [api]);

        const scrollNext = useCallback(() => {
            api?.scrollNext();
        }, [api]);

        const scrollTo = useCallback(
            (index: number) => {
                api?.scrollTo(index);
            },
            [api],
        );

        const handleKeyDown = useCallback(
            (event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === 'ArrowLeft') {
                    event.preventDefault();
                    scrollPrev();
                } else if (event.key === 'ArrowRight') {
                    event.preventDefault();
                    scrollNext();
                }
            },
            [scrollPrev, scrollNext],
        );

        useEffect(() => {
            if (!api || !setApi) {
                return;
            }

            setApi(api);
        }, [api, setApi]);

        useEffect(() => {
            if (!api) {
                return;
            }

            onInit(api);
            onSelect(api);
            api.on('reInit', onSelect);
            api.on('select', onSelect);

            return () => {
                api?.off('select', onSelect);
            };
        }, [api, onInit, onSelect]);

        return (
            <CarouselContext.Provider
                value={{
                    carouselRef,
                    api: api,
                    opts,
                    orientation:
                        orientation ||
                        (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
                    scrollPrev,
                    scrollNext,
                    canScrollPrev,
                    canScrollNext,
                    scrollSnaps,
                    scrollTo,
                    selectedIndex,
                }}>
                <div
                    ref={ref}
                    onKeyDownCapture={handleKeyDown}
                    className={cn('relative', className)}
                    role="region"
                    aria-roledescription="carousel"
                    {...props}>
                    {children}
                </div>
            </CarouselContext.Provider>
        );
    },
);

Carousel.displayName = 'Carousel';

const CarouselContent = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
    const { carouselRef, orientation } = useCarousel();

    return (
        <div ref={carouselRef} className="overflow-hidden">
            <div
                ref={ref}
                className={cn(
                    'flex',
                    orientation === 'horizontal'
                        ? '-ml-[1px]'
                        : '-mt-[1px] flex-col',
                    className,
                )}
                {...props}
            />
        </div>
    );
});

CarouselContent.displayName = 'CarouselContent';

const CarouselItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        const { orientation } = useCarousel();

        return (
            <div
                ref={ref}
                role="group"
                aria-roledescription="slide"
                className={cn(
                    'min-w-0 shrink-0 grow-0 basis-full',
                    orientation === 'horizontal' ? 'pl-[1px]' : 'pt-[1px]',
                    className,
                )}
                {...props}
            />
        );
    },
);

CarouselItem.displayName = 'CarouselItem';

const CarouselPrevious = forwardRef<
    HTMLButtonElement,
    ComponentProps<typeof Button>
>(({ className, variant = 'outline', size = 'icon', ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel();

    return (
        <Button
            ref={ref}
            variant={variant}
            size={size}
            className={cn(
                'absolute h-6 w-6 transition-opacity disabled:opacity-0',
                orientation === 'horizontal'
                    ? 'left-0 top-1/2 -translate-y-1/2'
                    : '-top-12 left-1/2 -translate-x-1/2 rotate-90',
                className,
            )}
            disabled={!canScrollPrev}
            onClick={scrollPrev}
            {...props}>
            <ArrowLeftIcon className="h-5 w-5" />
            <span className="sr-only">Previous slide</span>
        </Button>
    );
});

CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = forwardRef<
    HTMLButtonElement,
    ComponentProps<typeof Button>
>(({ className, variant = 'outline', size = 'icon', ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel();

    return (
        <Button
            ref={ref}
            variant={variant}
            size={size}
            className={cn(
                'absolute h-6 w-6 transition-opacity disabled:opacity-0',
                orientation === 'horizontal'
                    ? 'right-0 top-1/2 -translate-y-1/2'
                    : '-bottom-12 left-1/2 -translate-x-1/2 rotate-90',
                className,
            )}
            disabled={!canScrollNext}
            onClick={scrollNext}
            {...props}>
            <ArrowRightIcon className="h-5 w-5" />
            <span className="sr-only">Next slide</span>
        </Button>
    );
});

CarouselNext.displayName = 'CarouselNext';

const CarouselDots = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLElement> & {
        theme?: 'light' | 'dark';
    }
>(({ className, theme = 'light', ...props }, ref) => {
    const { scrollSnaps, scrollTo, selectedIndex } = useCarousel();

    return (
        <nav
            className={cn(
                'absolute bottom-0 left-0 right-0 z-10 flex justify-center gap-2 py-3',
                theme === 'light' ? 'text-white' : 'text-black',
                className,
            )}
            ref={ref}
            {...props}>
            {scrollSnaps.map((_, index) => (
                <CarouselDot
                    key={index}
                    onClick={() => scrollTo(index)}
                    selected={index === selectedIndex}>
                    <span className="sr-only">{index + 1}</span>
                </CarouselDot>
            ))}
        </nav>
    );
});

CarouselDots.displayName = 'CarouselDots';

const CarouselDot = forwardRef<
    HTMLButtonElement,
    HTMLAttributes<HTMLButtonElement> & {
        selected?: boolean;
    }
>(({ className, selected, ...props }, ref) => {
    return (
        <button
            ref={ref}
            className={cn(
                'h-2 w-2',
                selected === true ? 'bg-current' : 'bg-gray-400/85',
                className,
            )}
            {...props}
        />
    );
});

CarouselDot.displayName = 'CarouselDot';

export {
    Carousel,
    type CarouselApi,
    CarouselContent,
    CarouselDots,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
};
