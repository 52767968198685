import { XIcon } from '@ngg/icons';
import * as React from 'react';

import { cn } from '@/lib/utils';

type DialogCloseProps = {
    children?: React.ReactNode;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const DialogClose = React.forwardRef<HTMLButtonElement, DialogCloseProps>(
    ({ children, className, ...props }, ref) => (
        <button
            ref={ref}
            type="button"
            className={cn(
                'absolute right-0 top-0 flex h-13 w-13 items-center justify-center',
                className,
            )}
            {...props}>
            {children ?? (
                <>
                    <span className="sr-only">Close</span>
                    <XIcon />
                </>
            )}
        </button>
    ),
);

DialogClose.displayName = 'DialogClose';

export default DialogClose;
