import { Dialog } from '@headlessui/react';
import { motion } from 'framer-motion';
import React, { forwardRef, type ReactNode } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';

import { TRANSITION_CUBIC_BEZIER } from '@/config/transitions';

import DialogClose from './DialogClose';

const dialogContent = tv({
    base: [
        'z-50',
        'fixed',
        'flex',
        'flex-col',
        'w-full',
        'max-w-xs',
        'bg-white',
        'overflow-y-auto',
        'overflow-x-hidden',
    ],
    variants: {
        position: {
            left: ['left-0', 'top-0', 'h-full'],
            right: ['right-0', 'top-0', 'h-full'],
            center: [
                'max-h-screen',
                'max-w-lg',
                'left-[50%]',
                'top-[50%]',
                'translate-x-[-50%]',
                'translate-y-[-50%]',
            ],
        },
    },
});

const animateFromLeft = {
    initial: { x: '-100%' },
    animate: { x: 0 },
    exit: { x: '-100%' },
    transition: TRANSITION_CUBIC_BEZIER,
};

const animateFromRight = {
    initial: { x: '100%' },
    animate: { x: 0 },
    exit: { x: '100%' },
    transition: TRANSITION_CUBIC_BEZIER,
};

const animateFromCenter = {
    initial: { opacity: 0, scale: 0.9, y: '-50%', x: '-50%' },
    animate: { opacity: 1, scale: 1, y: '-50%', x: '-50%' },
    exit: { opacity: 0, scale: 0.9, y: '-50%', x: '-50%' },
    transition: TRANSITION_CUBIC_BEZIER,
};

function useDialogPositionAnimation(
    position: keyof typeof dialogContent.variants.position,
) {
    switch (position) {
        case 'left':
            return animateFromLeft;
        case 'right':
            return animateFromRight;
        case 'center':
            return animateFromCenter;
        default:
            return animateFromCenter;
    }
}

const DialogContent = forwardRef<
    React.ElementRef<typeof Dialog.Panel>,
    React.ComponentPropsWithoutRef<typeof Dialog.Panel> & {
        className?: string;
        closeComponent?: ReactNode;
        children?: ReactNode;
        open?: boolean;
        onClose?: () => void;
    } & VariantProps<typeof dialogContent>
>(
    (
        {
            className,
            children,
            position = 'center',
            onClose,
            closeComponent = <DialogClose onClick={onClose} />,
            ...props
        },
        ref,
    ) => {
        const animation = useDialogPositionAnimation(position);

        return (
            <motion.div className={dialogContent({ position })} {...animation}>
                <Dialog.Panel ref={ref} className={className} {...props}>
                    {closeComponent}
                    {children}
                </Dialog.Panel>
            </motion.div>
        );
    },
);

DialogContent.displayName = Dialog.Panel.displayName;

export default DialogContent;
