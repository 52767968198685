export const TRANSITION_DURATION = 0.3;
export const TRANSITION_LINEAR = {
    ease: 'linear',
    duration: TRANSITION_DURATION,
};
export const TRANSITION_EASE_IN = {
    ease: 'easeIn',
    duration: TRANSITION_DURATION,
};
export const TRANSITION_EASE_OUT = {
    ease: 'easeOut',
    duration: TRANSITION_DURATION,
};
export const TRANSITION_CUBIC_BEZIER = {
    ease: [0.43, 0.13, 0.23, 0.96],
    duration: TRANSITION_DURATION,
};
