'use client';

import type {
    KeyValue,
    RootLayoutQuery,
    RootLayoutSiteinfoFragment,
} from '@server/gql/graphql';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { Dictionary } from 'types/common';

import { createDictionary } from '@/utils/common';

type SiteInfoProps = Omit<RootLayoutSiteinfoFragment, 'globalDictionary'> & {
    globalDictionary: Dictionary;
    campaigns?: {
        slug: string;
        commerceId: string;
        campaignTitle: string;
        campaignBadge: string;
        campaignLabel: string;
        campaignStart: string;
        campaignEnd: string;
        showBadgeForAllProductsInCampaign: boolean;
    }[];
    wishlist?: { slug: string };
};

export const SiteInfoContext = createContext<SiteInfoProps | null>(null);

export const SiteInfoProvider = ({
    siteinfo,
    campaigns: _campaigns,
    wishlist: _wishlist,
    children,
}: {
    siteinfo?: RootLayoutSiteinfoFragment | null;
    campaigns: RootLayoutQuery['campaigns'];
    wishlist: RootLayoutQuery['wishlist'];
    children: ReactNode;
}) => {
    const state = useMemo(() => {
        const wishlist = _wishlist?.items.at(0);
        const campaigns = _campaigns?.items;
        if (!siteinfo) return {} as any;
        const { globalDictionary: dict, ...rest } = siteinfo ?? {};
        const globalDictionary = createDictionary(
            dict?.listCollection?.items as KeyValue[],
        );
        return { ...rest, globalDictionary, campaigns, wishlist };
    }, [siteinfo, _campaigns, _wishlist]);
    return (
        <SiteInfoContext.Provider value={state}>
            {children}
        </SiteInfoContext.Provider>
    );
};

export const useSiteInfo = () => {
    const context = useContext(SiteInfoContext);
    if (context === undefined)
        throw new Error('useSiteinfo must be used within a SiteInfoProvider');
    return context as SiteInfoProps;
};
