import { cn } from '@/lib/utils';

const DialogFooter = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
}) => <div className={cn('', className)} {...props} />;

DialogFooter.displayName = 'DialogFooter';

export default DialogFooter;
