import { Dialog } from '@headlessui/react';
import { motion } from 'framer-motion';
import * as React from 'react';

import { TRANSITION_CUBIC_BEZIER } from '@/config/transitions';
import { cn } from '@/lib/utils';

const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: TRANSITION_CUBIC_BEZIER,
};

const DialogOverlay = React.forwardRef<
    HTMLDivElement,
    {
        className?: string;
    }
>(({ className, ...props }, ref) => (
    <motion.div
        ref={ref}
        className={cn('fixed inset-0 bg-black/20', className)}
        aria-hidden="true"
        {...animation}
        {...props}
    />
));

DialogOverlay.displayName = Dialog.Backdrop.displayName;

export default DialogOverlay;
