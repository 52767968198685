import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

/**
 * When a value not in the default config is added to the tailwind config
 * it needs to be set her to avoid accidental removal
 *
 * @url https://github.com/dcastil/tailwind-merge/tree/v1.10.0
 */
const allowEverythingAfterKey = () => true;

const customTwMerge = extendTailwindMerge({
    classGroups: {
        text: ['text-xxs'],
        'max-w': [{ 'max-w': [allowEverythingAfterKey] }],
        btn: ['btn-primary', 'btn-outlined'],
    },
});

export type TailwindClasses = Parameters<typeof clsx>[0];

export function cn(...inputs: ClassValue[]) {
    return customTwMerge(clsx(inputs));
}
