import type { ComponentProps } from 'react';

type Props = ComponentProps<'svg'> & {
    size?: number;
};

const LoadingIcon = ({ size, ...props }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? 24}
            height={size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}>
            <path d="M21 12a9 9 0 1 1-6.219-8.56" />
        </svg>
    );
};

LoadingIcon.displayName = 'LoadingIcon';

export default LoadingIcon;
