'use client';

import { Dialog } from '@headlessui/react';

import DialogClose from './DialogClose';
import DialogContent from './DialogContent';
import DialogDescription from './DialogDescription';
import DialogFooter from './DialogFooter';
import DialogHeader from './DialogHeader';
import DialogOverlay from './DialogOverlay';
import DialogTitle from './DialogTitle';

const composedDialog = {
    Root: Dialog,
    Close: DialogClose,
    Content: DialogContent,
    Description: DialogDescription,
    Footer: DialogFooter,
    Header: DialogHeader,
    Overlay: DialogOverlay,
    Title: DialogTitle,
};

export { composedDialog as Dialog };
